@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local('Noto Sans Thin'), local('NotoSans-Thin'), url("./fonts/kr/NotoSansKR-Thin.woff") format('woff');
    unicode-range: U+AC00-D7A3, U+0030-0039;
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Noto Sans Light'), local('NotoSans-Light'), url("./fonts/kr/NotoSansKR-Light.woff") format('woff');
    unicode-range: U+AC00-D7A3, U+0030-0039;
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 350;
    font-display: swap;
    src: local('Noto Sans DemiLight'), local('NotoSans-DemiLight'), url("./fonts/kr/NotoSansKR-DemiLight.woff") format('woff');
    unicode-range: U+AC00-D7A3, U+0030-0039;
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Noto Sans Regular'), local('NotoSans-Regular'), url("./fonts/kr/NotoSansKR-Regular.woff") format('woff');
    unicode-range: U+AC00-D7A3, U+0030-0039;
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Noto Sans Medium'), local('NotoSans-Medium'), url("./fonts/kr/NotoSansKR-Medium.woff") format('woff');
    unicode-range: U+AC00-D7A3, U+0030-0039;
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Noto Sans Bold'), local('NotoSans-Bold'), url("./fonts/kr/NotoSansKR-Bold.woff") format('woff');
    unicode-range: U+AC00-D7A3, U+0030-0039;
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local('Noto Sans Black'), local('NotoSans-Black'), url("./fonts/kr/NotoSansKR-Black.woff") format('woff');
    unicode-range: U+AC00-D7A3, U+0030-0039;
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Noto Sans Regular'), local('NotoSans-Regular'), url("./fonts/ar/NotoSansArabic-Regular.woff") format('woff');
    unicode-range: U+600-06FF;
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Noto Sans Medium'), local('NotoSans-Medium'), url("./fonts/ar/NotoSansArabic-Medium.woff") format('woff');
    unicode-range: U+600-06FF;
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Noto Sans Bold'), local('NotoSans-Bold'), url("./fonts/ar/NotoSansArabic-Bold.woff") format('woff');
    unicode-range: U+600-06FF;
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Noto Sans Regular'), local('NotoSans-Regular'), url("./fonts/en/NotoSans-Regular.woff") format('woff');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Noto Sans Medium'), local('NotoSans-Medium'), url("./fonts/en/NotoSans-Medium.woff") format('woff');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Noto Sans Bold'), local('NotoSans-Bold'), url("./fonts/en/NotoSans-Bold.woff") format('woff');
}