@import "ckEditor.css";
@import "embla.css";

* {
  box-sizing: border-box;
  margin: 0;
  font-family: Noto Sans;
  padding: 0;
}

body {
  font-family: Noto Sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body .pac-container {
  z-index: 9999;
}

.asterisk {
  color: #f50057;
}
